import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  return <Page seo={{
    title: "Q&A",
    description: "Q&A FAQ Frequently Asked Questions "
  }}>
      <Page.Header downArrows>
        <Page.Header.Title>Q&A</Page.Header.Title>
        <Page.Header.Subtitle>Frequently Asked Questions</Page.Header.Subtitle>
      </Page.Header>
      <Page.Body sx={{
      py: [5, 6]
    }}>{children}</Page.Body>
    </Page>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What is Almagesto all about?`}</h2>
    <p>{`Almagesto allows you to create customizable astronomical prints to celebrate special events with an high quality interior design product.`}</p>
    <p>{`By using our `}<a parentName="p" {...{
        "href": "https://almagesto.xyz/play"
      }}>{`configurator`}</a>{` you can generate the orbits of the Solar System celestial objects during the timespan of your choice as they appear from whatever planet you want. For more info visit our landing page at `}<a parentName="p" {...{
        "href": "https://almagesto.xyz"
      }}>{`almagesto.xyz`}</a>{` or contact us at `}<a parentName="p" {...{
        "href": "mailto:info@almagesto.xyz"
      }}>{`info@almagesto.xyz`}</a>{`.`}</p>
    <h2>{`How can I create my own personal Almagesto print?`}</h2>
    <p>{`You can design your personal Almagesto print with our `}<a parentName="p" {...{
        "href": "https://almagesto.xyz/play"
      }}>{`configurator`}</a>{`.`}</p>
    <h2>{`What about the delivery time?`}</h2>
    <p>{`You will receive the print within 2 or 3 weeks depending on your country.`}</p>
    <h2>{`How about the shipping costs?`}</h2>
    <p>{`We have FREE Worldwide shipping.`}</p>
    <h2>{`What should I do if I receive a damaged poster?`}</h2>
    <p>{`We print your poster in a way to reduce light reflections. This printing technique makes them delicate to scratches so we advise our customers to manage them carefully.
If you receive an already damaged poster please, contact us at `}<a parentName="p" {...{
        "href": "mailto:support@almagesto.xyz"
      }}>{`support@almagesto.xyz`}</a>{` and attach some photos of the damaged product. After checking the state of your product we will take care to send you a new print.`}</p>
    <h2>{`Are the posters shipped with a frame?`}</h2>
    <p>{`No, to contain the shipping costs we deliver posters rolled up in a tube. You have to buy your own frame separately.`}</p>
    <h2>{`I can't find a suitable frame...`}</h2>
    <p>{`Almagesto prints are 30x40cm, 50x70cm and 61x91cm. These are all international standard sizes.`}</p>
    <h2>{`What are Almagesto special collections?`}</h2>
    <p>{`Aside from the custom poster concept, we also produce some fixed graphics posters and collections:`}</p>
    <p><strong parentName="p">{`THE NEWTONIAN APPLE`}</strong>{`: a special poster to celebrate the life and work of Isaac Newton.`}</p>
    <p><strong parentName="p">{`THE COPERNICAN REVOLUTION`}</strong>{`: a special pair of poster to celebrate the Copernican paradigm shift from the Geocentric to the Heliocentric perspetive.`}</p>
    <p><strong parentName="p">{`THE ALMAGEST HERITAGE`}</strong>{`: a special trio of posters designed to celebrate the three historical phases of the Almagest through history.`}</p>
    <p><strong parentName="p">{`DUNE SAGA TRIBUTE`}</strong>{`: a special designed poster to celebrate the awesome Sci-fi saga by Frank Herbert.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      